<template>
  <div class="section-content section-pickup pt-4 w-100">
    <b-container class="container-xlg pl-xxl-0 pr-xxl-0">
      <div class="top-event d-flex flex-column flex-md-column">
        <esports-title
          title="Game"
          class="pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page"
          sub_title="人気ゲーム"
        />
      </div>
      <esports-pickup-loading v-if="isLoading" :m_col="1" :events="6" />
      <b-row class="mt-md-3" v-else cols="1" cols-md="3" align-v="stretch">
        <b-col v-for="(event, index) in gameList" :key="index">
          <b-card class="mb-4 mb-md-3 mb-lg-5 border-0 rounded-0 position-relative card-stagger" no-body>
            <div class="img-thumb">
              <b-card-img-lazy
                :src="event.picture"
                alt="Image"
                class="rounded-0 img-event"
                v-bind="mainProps('#fff')"
                show
              ></b-card-img-lazy>
            </div>
            <div class="article-content">
              <h4 class="mb-0 cursor-pointer" role="button">
                {{ event.title }}
              </h4>
            </div>
          </b-card>
        </b-col>
      </b-row>
      <esports-not-result v-if="hideBeforeLoading && !isResult"></esports-not-result>
      <div
        class="
          limit-event
          filter-search
          d-flex
          justify-content-between
          mt-0
          mb-1
          mt-md-0
          mb-md-0
        "
        v-if="isResult"
      >
         <span class="txt-lable">{{ rows }}件中{{ rowsVisible }}件表示</span>
        <b-form-select
          v-model="perPage"
          :options="options_limit"
          class="mb-3 text-truncate select-limit"
        ></b-form-select>
      </div>
      <b-pagination
        v-if="isPaging"
        v-model="page"
        :total-rows="rows"
        :per-page="perPage"
        size="md"
        align="center"
        class="page-nav my-3"
      >
        <template #first-text="{ disabled }">
          <esports-icon-double-arrow-left v-if="disabled" />
          <esports-icon-double-arrow-left v-else color="#231F20" />
        </template>
        <template #prev-text="{ disabled }">
          <esports-icon-arrow-left v-if="disabled" />
          <esports-icon-arrow-left v-else color="#231F20" />
        </template>

        <template #last-text="{ disabled }">
          <esports-icon-double-arrow-right v-if="disabled" />
          <esports-icon-double-arrow-right v-else color="#231F20" />
        </template>
        <template #next-text="{ disabled }">
          <esports-icon-arrow-right v-if="disabled" />
          <esports-icon-arrow-right v-else color="#231F20" />
        </template>
      </b-pagination>
      <esports-loading-button
        class="overlay-content-page"
        width="100"
        height="100"
        v-if="isLoading"
      />
    </b-container>
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "GameList",
  data() {
    return {
      gameList: [],
      limit: true,
      isLoading: false,
      options_limit: [
        {
          value: 10,
          text: "10件",
        },
        {
          value: 20,
          text: "20件",
        },
        {
          value: 40,
          text: "40件",
        },
        {
          value: 60,
          text: "60件",
        },
        {
          value: 70,
          text: "70件",
        },
        {
          value: 100,
          text: "100件",
        },
      ],
      page: 1,
      rows: 1,
      perPage: 20,
      currentPage: 1
    };
  },
  watch: {
    perPage(nVal) {
      this.page = 1;
      this.currentPage = 1;
      this.getGameList({
        perPage: this.perPage,
        page: this.page,
      });
    },
    page(nVal) {
      if(this.page === this.currentPage) {
        this.currentPage = 1;
        return;
      }
      this.currentPage = nVal;

      this.getGameList({
        perPage: this.perPage,
        page: this.page,
      });
    },
  },
  computed: {
    isPaging() {
      return this.rows > this.perPage;
    },
    isResult() {
      return this.gameList.length;
    },
    rowsVisible() {
      if (this.page === 1) {
        return this.gameList.length;
      }
      return this.perPage * (this.page - 1) + this.gameList.length;
    },
  },
  async mounted() {
    this.isLoading = true;
    await this.getGameList({
      perPage: this.perPage,
      page: this.page,
    });
    this.hideBeforeLoading = true;
  },
  methods: {
    async getGameList(params) {
      
      const result = await this.$store
        .dispatch("game/getGameList", params)
        .catch((err) => {
          this.isLoading = false;
        });

      if (result) {
        this.rows = result.total;

        this.gameList = result.data.map((game, index) => {
          game.picture = `${process.env.VUE_APP_BACKEND + game.picture}`;

          if (game.startDate) {
            game.startDate = moment(game.startDate).format("YYYY-MM-DD");
          }
          return game;
        });

        this.isLoading = false;
        this.initStagger();
        this.scrollToTop();
      }
    },
  },
};
</script>