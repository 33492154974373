var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"section-content section-pickup pt-4 w-100"},[_c('b-container',{staticClass:"container-xlg pl-xxl-0 pr-xxl-0"},[_c('div',{staticClass:"top-event d-flex flex-column flex-md-column"},[_c('esports-title',{staticClass:"pb-2 pt-2 mb-2 mt-2 mt-xxl-4 pb-xxl-5 article-page",attrs:{"title":"Game","sub_title":"人気ゲーム"}})],1),(_vm.isLoading)?_c('esports-pickup-loading',{attrs:{"m_col":1,"events":6}}):_c('b-row',{staticClass:"mt-md-3",attrs:{"cols":"1","cols-md":"3","align-v":"stretch"}},_vm._l((_vm.gameList),function(event,index){return _c('b-col',{key:index},[_c('b-card',{staticClass:"mb-4 mb-md-3 mb-lg-5 border-0 rounded-0 position-relative card-stagger",attrs:{"no-body":""}},[_c('div',{staticClass:"img-thumb"},[_c('b-card-img-lazy',_vm._b({staticClass:"rounded-0 img-event",attrs:{"src":event.picture,"alt":"Image","show":""}},'b-card-img-lazy',_vm.mainProps('#fff'),false))],1),_c('div',{staticClass:"article-content"},[_c('h4',{staticClass:"mb-0 cursor-pointer",attrs:{"role":"button"}},[_vm._v(" "+_vm._s(event.title)+" ")])])])],1)}),1),(_vm.hideBeforeLoading && !_vm.isResult)?_c('esports-not-result'):_vm._e(),(_vm.isResult)?_c('div',{staticClass:"\n        limit-event\n        filter-search\n        d-flex\n        justify-content-between\n        mt-0\n        mb-1\n        mt-md-0\n        mb-md-0\n      "},[_c('span',{staticClass:"txt-lable"},[_vm._v(_vm._s(_vm.rows)+"件中"+_vm._s(_vm.rowsVisible)+"件表示")]),_c('b-form-select',{staticClass:"mb-3 text-truncate select-limit",attrs:{"options":_vm.options_limit},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}})],1):_vm._e(),(_vm.isPaging)?_c('b-pagination',{staticClass:"page-nav my-3",attrs:{"total-rows":_vm.rows,"per-page":_vm.perPage,"size":"md","align":"center"},scopedSlots:_vm._u([{key:"first-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('esports-icon-double-arrow-left'):_c('esports-icon-double-arrow-left',{attrs:{"color":"#231F20"}})]}},{key:"prev-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('esports-icon-arrow-left'):_c('esports-icon-arrow-left',{attrs:{"color":"#231F20"}})]}},{key:"last-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('esports-icon-double-arrow-right'):_c('esports-icon-double-arrow-right',{attrs:{"color":"#231F20"}})]}},{key:"next-text",fn:function(ref){
var disabled = ref.disabled;
return [(disabled)?_c('esports-icon-arrow-right'):_c('esports-icon-arrow-right',{attrs:{"color":"#231F20"}})]}}],null,false,2722950511),model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}}):_vm._e(),(_vm.isLoading)?_c('esports-loading-button',{staticClass:"overlay-content-page",attrs:{"width":"100","height":"100"}}):_vm._e()],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }