<template>
  <div class="tickets-page game-page players-page w-100">
    <GameList class="pb-2 pb-xxl-5" />
    <SectionPhone class="mt-5 pb-3 pt-3 pt-md-5 pb-md-5 pb-xxl-5 bg-white" />
  </div>
</template>

<script>
import GameList from "./GameList";
import SectionPhone from "@/views/Landing/SectionPhone";

export default {
  name: "Games",
  title: "games",
  components: {
    GameList,
    SectionPhone
  },
};
</script>